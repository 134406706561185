<template>
  <el-dialog
    class="small-padding"
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogVisible"
    width="1400px"
    top="2vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form ref="formValidate" class="form" label-width="120px" :model="uiSetting">
        <el-form-item :label="$t('uiSetting.systemName')" prop="noticeTitle">
          <el-input
            v-model="uiSetting.systemName"
            :placeholder="$t('common.pleaseEnter')"
            :maxlength="30"></el-input>
        </el-form-item>

        <el-form-item v-if="uiSetting.companyId !== 0" :label="$t('uiSetting.companyName')" prop="companyName">
          <el-input v-model="uiSetting.companyName" :placeholder="$t('common.pleaseSelect')" readonly>
            <el-button slot="append" icon="el-icon-search" @click="$refs.selectcompany.open(0)"></el-button>
          </el-input>
          <company-select ref="selectcompany" @select="onSelect"></company-select>
        </el-form-item>

        <el-row>
          <el-col :span="8">
            <div class="grid-content">
              <el-form-item :label="$t('uiSetting.logoLarge')">
                <el-upload
                  :action="getAction"
                  :on-success="handleSuccessLogoLarge"
                  :file-list="logoFile"
                  :limit="1"
                  :on-error="handlerError"
                  :on-exceed="onExceed"
                  :on-remove="handleRemoveLogoLarge"
                  :before-upload="beforeAvatarUpload"
                  accept="image/*"
                  list-type="picture-card">
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <el-form-item :label="$t('uiSetting.logoSmall')">
                <el-upload
                  :action="getAction"
                  :on-success="handleSuccessLogoSmall"
                  :file-list="logoSmallFile"
                  :limit="1"
                  :on-error="handlerError"
                  :on-exceed="onExceed"
                  :on-remove="handleRemoveLogoSmall"
                  :before-upload="beforeAvatarUpload"
                  accept="image/*"
                  list-type="picture-card">
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <el-form-item :label="$t('uiSetting.faviconIcon')">
                <el-upload
                  :action="getAction"
                  :on-success="handleSuccessFaviconIcon"
                  :file-list="faviconIconFile"
                  :limit="1"
                  :on-error="handlerError"
                  :on-exceed="onExceed"
                  :on-remove="handleRemoveFaviconIcon"
                  :before-upload="beforeAvatarUpload"
                  accept="image/*"
                  list-type="picture-card">
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </div>
          </el-col>
        </el-row>

        <el-form-item :label="$t('uiSetting.banner')">
          <el-upload
            :action="getAction"
            :on-success="handleSuccessBanner"
            :on-remove="handleRemoveBanner"
            :on-error="handlerError"
            :before-upload="beforeAvatarUpload"
            :file-list="bannerFiles"
            :limit="5"
            :on-exceed="onExceed"
            list-type="picture-card">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <el-form-item :label="$t('uiSetting.appBanner')">
          <el-upload
            :action="getAction"
            :on-success="handleSuccessAppBanner"
            :on-remove="handleRemoveAppBanner"
            :on-error="handlerError"
            :before-upload="beforeAvatarUpload"
            :file-list="appBannerFiles"
            :limit="5"
            :on-exceed="onExceed"
            list-type="picture-card">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import CompanySelect from "../company/CompanySelect.vue";
  import ElFormItem from "element-ui/packages/form/src/form-item.vue";

  const moduleName = "uiSettings";

  export default {
    components: {
      ElFormItem,
      CompanySelect,
    },
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        bannerFiles: [],
        appBannerFiles: [],
        uiSetting: {
          id: 0,
          systemName: "",
          logoLarge: "",
          logoSmall: "",
          banners: "",
          appBanners: "",
          companyId: 0,
          faviconIcon: "",
          companyName: "",
        },
      };
    },
    computed: {
      title() {
        return (this.uiSetting.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " + this.$t("route.uiSetting");
      },
      logoFile() {
        if (this.uiSetting.logoLarge) {
          return [{url: this.getFullUrl(this.uiSetting.logoLarge)}];
        }
        return [];
      },
      logoSmallFile() {
        if (this.uiSetting.logoSmall) {
          return [{url: this.getFullUrl(this.uiSetting.logoSmall)}];
        }
        return [];
      },
      faviconIconFile() {
        if (this.uiSetting.faviconIcon) {
          return [{url: this.getFullUrl(this.uiSetting.faviconIcon)}];
        }
        return [];
      },
      getAction() {
        return window.config.SERVER_URL + "uiSettings/upload";
      },
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.uiSetting.id = id;
        if (id > 0) {
          this.getData();
        }
        if (id === -1) {
          this.getData();
        }
      },
      getData() {
        this.contentLoading = true;
        this.saveDisabled = true;
        this.$api.getById(moduleName, this.uiSetting.id).then(res => {
          this.contentLoading = false;
          this.saveDisabled = false;
          this.uiSetting = res.data;
          /* eslint-disable */
          if (this.uiSetting.banners) {
            for (const banner of JSON.parse(this.uiSetting.banners)) {
              this.bannerFiles.push({url: this.getFullUrl(banner), path: banner});
            }
          }
          if (this.uiSetting.appBanners) {
            for (const banner of JSON.parse(this.uiSetting.appBanners)) {
              this.appBannerFiles.push({url: this.getFullUrl(banner), path: banner});
            }
          }
          /* eslint-enable */
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      onDialogClose() {
        this.$refs.formValidate.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      beforeAvatarUpload(file) {
        let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
        let isJPG = (fileType === "jpg" || fileType === "jpeg");
        let isPNG = fileType === "png";
        let isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJPG && !isPNG) {
          this.$message.error(this.$t("uiSetting.tip.fileType"));
        }
        if (!isLt2M) {
          this.$message.error(this.$t("uiSetting.tip.isLt2M"));
        }
        return (isJPG || isPNG) && isLt2M;
      },
      handleSuccessLogoLarge(response) {
        this.uiSetting.logoLarge = response;
      },
      handleSuccessLogoSmall(response) {
        this.uiSetting.logoSmall = response;
      },
      handleSuccessFaviconIcon(response) {
        console.log(response);
        this.uiSetting.faviconIcon = response;
      },
      handlerError(err, file, fileList) {
        alert(err);
      },
      onExceed(files, fileList) {
        alert(this.$t("uiSetting.tip.pictures"));
      },
      handleSuccessBanner(response, file, fileList) {
        file.path = response;
        this.bannerFiles = fileList;
      },
      handleRemoveBanner(file, fileList) {
        this.bannerFiles = fileList;
      },
      handleSuccessAppBanner(response, file, fileList) {
        file.path = response;
        this.appBannerFiles = fileList;
      },
      handleRemoveAppBanner(file, fileList) {
        this.appBannerFiles = fileList;
      },
      handleRemoveLogoLarge(file, fileList) {
        this.uiSetting.logoLarge = "";
      },
      handleRemoveLogoSmall(file, fileList) {
        this.uiSetting.logoSmall = "";
      },
      handleRemoveFaviconIcon(file, fileList) {
        this.uiSetting.faviconIcon = "";
      },
      getFullUrl(path) {
        return window.config.SERVER_URL + path;
      },
      dialogReset() {
        this.saveDisabled = false;
        this.contentLoading = false;
        this.$nextTick(() => {
          this.$refs.formValidate.resetFields();
        });
      },
      onSelect(row) {
        this.uiSetting.companyId = row.id;
        this.uiSetting.companyName = row.name;
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            let banners = [];
            for (const file of this.bannerFiles) {
              banners.push(file.path);
            }
            let appBanners = [];
            for (const file of this.appBannerFiles) {
              appBanners.push(file.path);
            }
            this.uiSetting.banners = JSON.stringify(banners);
            this.uiSetting.appBanners = JSON.stringify(appBanners);
            this.$api.save(moduleName, this.uiSetting).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.uiSetting.id === 0);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
.img {
  float: left;
  width: 50%;
  word-wrap: break-word;
}

.test {
  float: right;
  position: relative;
  width: 50%;
}
</style>
