<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="filter" url="uiSettings">
      <template slot="adSearch">
        <vm-search :label="$l('uiSetting.systemName', '系统名称')">
          <el-input v-model.trim="filter.systemName" :placeholder="$t('common.pleaseEnter')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('uiSetting.companyName', '公司')">
          <el-input v-model.trim="filter.companyName" :placeholder="$t('common.pleaseEnter')" clearable></el-input>
        </vm-search>
      </template>
      <el-table-column prop="systemName" :label="$l('uiSetting.systemName', '系统名称')"
        show-overflow-tooltip></el-table-column>
      <el-table-column prop="companyName" :label="$l('uiSetting.companyName', '公司')"
        show-overflow-tooltip></el-table-column>
      <el-table-column v-if="editAuth" :label="$t('common.operation')" width="100" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{ $t("common.delete") }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-dialog ref="editPage" @save-success="getList($event ? 1 : currentPage)"></edit-dialog>
  </div>
</template>
<script>
const moduleName = "uiSettings";

import EditDialog from "./UiSettingEdit.vue";
export default {
  components: { EditDialog },
  data() {
    return {
      editAuth: this.$auth(801),
      importLoading: false,
      loading: true,
      filter: {
        systemName: "",
        companyName: "",
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    handleEdit(row) {
      this.$refs.editPage.open(row.id);
      console.log(row.id);
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.delete") + " " + this.$t("uiSetting.systemName") + " " + row.systemName + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$api.deleteById(moduleName, row.id).then(() => {
          console.log(row.id);
          this.getList(1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      });
    },
  },
};
</script>
